
// This module provides a simple interface to the browser's local storage.
// It provides functions to save and retrieve data from local storage.
// The data is stored as a JSON string.
export const saveData = (storageKey: string, data: Record<string, any>): void => {
    localStorage.setItem(storageKey, JSON.stringify(data));
};

export const getData = (storageKey: string): Record<string, any> => {
    const blob = localStorage.getItem(storageKey);
    if (blob === null) {
        return {};
    }
    try {
        return JSON.parse(blob);
    } catch (error) {
        console.error(`Failure parsing the blob as JSON: ${error}`);
        return {};
    }
};