// Demographic questions are really just vehicles for a control type
import React, { useEffect, useState } from 'react';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { Heading, Flex, Para, Box, Button, Label } from 'workspace-core-ui';
import { navigate } from 'gatsby';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import styled from 'styled-components';
import css from '@styled-system/css';
import useTranslation, { translationMissing } from '@hooks/useTranslation';
import { setHeaderType } from '@slices/gameStateSlice';
import { actions as routeActions } from '@slices/routeSlice';
import { batchLogItem, logItem } from '@slices/loggingSlice';
import ControlCenter, {
  ControlResponse,
} from '@components/controls/ControlCenter';
import DemoWhyModalButton from '@containers/DemoWhyModalButton';
import ControlImage from '@components/controls/ControlImage';
import { grabImage } from '@utils/grabImage';
import Input from 'workspace-core-ui/Input';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import useSound from '@hooks/useSound';
import { LogData } from '@utils/loggingClient';
import useNavigateLog from '@hooks/useNavigateLog';
import { wrap } from '@utils/wrap';
import { WINDOW_HASH } from '@sharedConstants';
import { setProfile, selectCurrentModule, selectProfile } from '../module/profile-slice';
import { Demographic, Content, ControlData, TypeOfControl } from '../../types';
import { Profile } from '../module/profile';

const DemoWrapper = styled(Flex)(
  css({
    mt: 'auto',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    isolation: 'isolate',
  }),
);

const ItemsWrapper = styled(Box).attrs({ as: 'form' })(
  css({
    position: 'absolute',
    padding: 'inherit',
    mx: 'auto',
    left: 0,
    right: 0,
    width: '100%',
  }),
);

interface CurtainedSectionsProps {
  /** whatever affirmative option the user chooses will trigger this function */
  handleCurtainedSelection: (
    e: ControlResponse,
    loopingPageIndex: number,
    paginate: (scalar: number) => void,
  ) => void;
  /** an array of specific screens that we will show either before or after the demo main content */
  // TODO: defined this more?
  viewData: Array<Demographic<ControlData<Content[]>>>;
  /** whether to render the helper text of the section as a button that opens up a generic modal with content from the game elements table */
  hasMoreInfoModal?: boolean;
  /** sourcing whatever symbol is associated with the header text, this option will show that item above the header text */
  useHeaderImage?: boolean;
}

// a generic component that is meant to be at either the head or tail or the demographic survey experience
const CurtainedSections = ({
  handleCurtainedSelection,
  viewData,
  hasMoreInfoModal = false,
  useHeaderImage = false,
}: CurtainedSectionsProps) => {
  // using standard loop controls even though we really can only go in one direction
  const [[page], setPage] = useState([0, 0]);
  const loopingPageIndex = wrap(0, viewData.length, page);
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  // TODO: learn typescript
  const { Control_Type, Possible_Answers, Header_Text, Help_Text } =
    viewData[loopingPageIndex].Controls;
  const { g } = useTranslation();
  return (
    <>
      <Flex flex={1} />
      <Flex flexDirection="column" flex={1}>
        <Box>
          {useHeaderImage && Header_Text?.Symbol && (
            <ControlImage
              sx={{
                mb: 5,
              }}
              {...grabImage(Header_Text.Symbol)}
            />
          )}
          <Heading variant="h1" mb={4} data-testid="claim-heading">
            {g(Header_Text, false)}
          </Heading>
          {hasMoreInfoModal ? (
            <DemoWhyModalButton
              buttonText={g(Help_Text, false)}
              mt={4}
              mb={6}
            />
          ) : (
            <Para variant="p3">{g(Help_Text, false)}</Para>
          )}
        </Box>
      </Flex>
      <Flex flex={0.5} justifyContent="center">
        <ControlCenter
          appearance="tertiary"
          setSubmittedAnswer={e => {
            handleCurtainedSelection(e, loopingPageIndex, paginate);
          }}
          typeOfControl={Control_Type}
          possibleAnswers={Possible_Answers}
        />
      </Flex>
    </>
  );
};

interface CheckboxSectionProps {
  Control_Type: TypeOfControl;
  Header_Text: Content;
  Help_Text: Content;
  Possible_Answers: Content[];
  Name: string;
  setDemoForm: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
    }>
  >;
}

const CheckboxSection = React.memo(
  ({
    Control_Type,
    Header_Text,
    Help_Text,
    Possible_Answers,
    Name,
    setDemoForm,
  }: CheckboxSectionProps) => {
    const [selectedOther, setSelectedOther] = useState<boolean>(false);
    const { g, t } = useTranslation();

    const onCheckboxesUpdated = (currentGroupValues: string[]) => {
      setDemoForm(form => ({
        ...form,
        [Name]: currentGroupValues ?? [],
      }));
      setSelectedOther(currentGroupValues.includes('other'));
    };

    return (
      <Box mb={11}>
        <Flex flexDirection="column" mb={3}>
          <Heading mb={1} variant="h2">
            <CustomMdxRenderer>{g(Header_Text, true)}</CustomMdxRenderer>
          </Heading>
          <Para mb={[4, 6, 6]} variant="p3" as="span">
            <CustomMdxRenderer>{g(Help_Text, true)}</CustomMdxRenderer>
          </Para>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          maxWidth="max(50vw, 250px)"
          m="0 auto"
          mb={6}
        >
          <ControlCenter
            typeOfControl={Control_Type}
            setSubmittedAnswer={onCheckboxesUpdated}
            possibleAnswers={Possible_Answers}
          />
          <AnimatePresence>
            {selectedOther && (
              <Box
                as={motion.div}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                mt={6}
                width="100%"
              >
                <Input
                  autoComplete="off"
                  maxLength="100"
                  margin="0 auto"
                  label={t('Specify Other')}
                  onInput={e => {
                    setDemoForm(form => {
                      // we need to transform our string 'other' in the array, to be rich with text, like other: abcde
                      // cut out other from list
                      const otherRemovedFormSlice = form[Name].filter(
                        formEntry => !formEntry.match(/^other:?|^other?/),
                      );
                      // now recreate that other, but this time with the other text appended, if any if supplied,
                      const newFormSlice = [
                        ...otherRemovedFormSlice,
                        `other:${e.target.value}`,
                      ];
                      return {
                        ...form,
                        // keep our old values in array form, but modify the "other" to be an object
                        [Name]: newFormSlice,
                      };
                    });
                  }}
                />
              </Box>
            )}
          </AnimatePresence>
        </Flex>
      </Box>
    );
  },
);

const SubmitButton = ({
  demoForm,
  setCurrentView,
  thankyouViewData,
  nextRoute,
  isDisabled,
}) => {
  const { playSound } = useSound();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentModule = useAppSelector(selectCurrentModule);
  const { userId } = useAppSelector(state => state.logging);

  return (
    <Button
      isDisabled={isDisabled}
      variant="formSubmit"
      buttonSize="medium"
      data-testid="submit"
      onPress={() => {
        playSound('Button');
        // send finished form data, all items are optional
        const logShapedDemoAnswers = Object.keys(demoForm).map(key => ({
          answers: demoForm[key],
          question_name: key,
          collection_name: 'demographics',
        })) as (LogData & { question_name: string })[];
        const profile = Profile.createProfile(userId || '', currentModule?.Name, logShapedDemoAnswers);
        dispatch(setProfile({ profile }));
        dispatch(batchLogItem(logShapedDemoAnswers));
        // send the user to the thank you pages if they exist
        if (thankyouViewData.length > 0) {
          setCurrentView('thankyouView');
        } else {
          navigate(nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`, {
            replace: true,
          });
        }
      }}
    >
      <Label variant="l1">{t('Submit Button')}</Label>
    </Button>
  );
};

/** turn an array of screen data into 3 contiguous chunks, the head and tail being those marked as is_curtain */
export const trisectScreenData = (
  screenData: Demographic<ControlData<Content[]>>[],
): Array<Array<Demographic<ControlData<Content[]>>>> => {
  let completedFirstChunk = false;
  if (!Array.isArray(screenData)) {
    return [[], [screenData], []];
  }
  return screenData?.reduce(
    (acc, screen) => {
      if (!completedFirstChunk) {
        if (screen.Is_Curtain) {
          acc[0].push(screen);
        } else {
          completedFirstChunk = true;
          acc[1].push(screen);
        }
      } else if (screen.Is_Curtain) {
        acc[2].push(screen);
      } else {
        acc[1].push(screen);
      }
      return acc;
    },
    [[], [], []] as Array<Array<Demographic<ControlData<Content[]>>>>,
  );
};

export type Props = {
  pageContext: {
    index: number;
    url: string;
    typeOfScreen: string;
    screenData: Demographic<ControlData<Content[]>>[];
  };
}

const DemographicsPage = (props: Props) => {
  useNavigateLog({ questionName: 'demographics' });
  const { pageContext } = props;
  const { screenData } = pageContext;
  // we need to sift our array into 3 chunks, the consent questions, demo questions, and the post demo "thank you" type screen
  // to get this, we reduce our array into 3 chunks, throwing items according to if they land before or after the "meat" (demo questions themselves)
  // anything uncurtained is considered a demo question
  const [consentViewData, questionsViewData, thankyouViewData] =
    trisectScreenData(screenData);

  // we must first ask user if they are willing to share info, ideally this section comprises of binary type controls
  // so the initial state is set, depending on whether there is consentview data
  const [currentView, setCurrentView] = useState<
    'consentView' | 'questionsView' | 'thankyouView'
  >(consentViewData.length > 0 ? 'consentView' : 'questionsView');
  const { nextRoute } = useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const { playSound } = useSound();
  const [demoForm, setDemoForm] = useState<{ [key: string]: string[] }>({});
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);
  const currentModule = useAppSelector(selectCurrentModule);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
    dispatch(
      routeActions.inferNextRoute({ compareAgainst: props.pageContext.url, profile, module: currentModule?.Name }),
    );
  }, [dispatch, props.pageContext.url, profile]);

  useEffect(() => {
    // check if all required fields have been filled out
    // if we have no required prop, we assume everything is "filled" by default
    if (
      questionsViewData.every(screen => screen?.Controls?.Is_Required === false)
    ) {
      setRequiredFieldsFilled(true);
    } else {
      Object.entries(demoForm).forEach(([k, v], index) => {
        if (
          questionsViewData[index].Name === k &&
          questionsViewData[index]?.Controls?.Is_Required
        ) {
          setRequiredFieldsFilled(v?.length > 0);
        }
      });
    }
  }, [demoForm, questionsViewData]);

  const title = t('Demographics title');

  return (
    <Layout shouldAnimate={props?.shouldAnimate} backgroundColor="background">
      <BodyWrapper p={5}>
        {currentView === 'questionsView' && (
          <LayoutGroup>
            <DemoWrapper>
              <ItemsWrapper>
                {!translationMissing(title) && (
                  <Heading mb={0} mt={12} variant="h2">
                    {title}
                  </Heading>
                )}
                {questionsViewData.map(({ Controls, Name }, index) => (
                  <div key={Name}>
                    {index === 0 && <Box height={['10vh', '15vh', '15vh']} />}
                    <CheckboxSection
                      {...Controls}
                      Name={Name}
                      setDemoForm={setDemoForm}
                    />
                    {/* last button submit */}
                    {index === questionsViewData.length - 1 && (
                      <Box as={motion.div} mb={4} layout>
                        <SubmitButton
                          isDisabled={requiredFieldsFilled === false}
                          demoForm={demoForm}
                          setCurrentView={setCurrentView}
                          thankyouViewData={thankyouViewData}
                          nextRoute={nextRoute}
                        />
                      </Box>
                    )}
                  </div>
                ))}
              </ItemsWrapper>
            </DemoWrapper>
          </LayoutGroup>
        )}
        {currentView === 'consentView' && (
          <CurtainedSections
            useHeaderImage
            hasMoreInfoModal
            viewData={consentViewData}
            handleCurtainedSelection={(e, viewIndex, paginate) => {
              playSound('Button');
              if (e?.controlValue) {
                // user chose true, thereby accepting to do demographic survey
                if (viewIndex === consentViewData.length - 1) {
                  // log the consent event
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'consent',
                      target: 'yes',
                    }),
                  );

                  setCurrentView('questionsView');
                } else {
                  // we still have more items to look at in this set
                  paginate(1);
                }
              } else {
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'consent',
                    target: 'no',
                  }),
                );
                // user did not consent, therefore just move on
                navigate(nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`, {
                  replace: true,
                });
              }
            }}
          />
        )}
        {currentView === 'thankyouView' && (
          <CurtainedSections
            useHeaderImage
            viewData={thankyouViewData}
            handleCurtainedSelection={(e, viewIndex, paginate) => {
              playSound('Button');
              // user chose true, thereby continuing forward, only one option is really honored here
              if (viewIndex === thankyouViewData.length - 1) {
                navigate(nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`, {
                  replace: true,
                });
              } else {
                paginate(1);
              }
            }}
          />
        )}
      </BodyWrapper>
    </Layout>
  );
};

export default DemographicsPage;
