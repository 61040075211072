// Demographic questions are really just vehicles for a control type
import React from 'react';
import Seo from '@containers/Seo';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import DemographicsV1 from './Demographics-v1';
import DemographicsV2 from './Demographics-v2';

const Demographics =
  checkFeatureFlag('MODULES') === 'FLAG_VALID'
    ? DemographicsV2
    : DemographicsV1;
export const Head = () => <Seo />;

export default Demographics;
